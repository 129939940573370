import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Paper, Typography } from "@mui/material";

export function SortableItem({ id }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id });

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        padding: "8px",
        marginBottom: "8px",
        backgroundColor: isDragging ? "#90caf9" : "#fff",
        cursor: "grab",
        transition: "background-color 0.2s",
        transform: CSS.Transform.toString(transform),
        opacity: isDragging ? 0.5 : 1, // Mantener visible mientras se arrastra
      }}
      elevation={2}
      {...attributes}
      {...listeners}
    >
      <Typography>{id}</Typography>
    </Paper>
  );
}
