import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import debounce from 'lodash.debounce';
import React, { useCallback, useState } from 'react'
import { actualizarRolesYDepartamentos } from '../../../services/apis';

export const PermisosOpciones = ({ roles, opciones, title, nombreArray }) => {
    // Estado local para opciones con roles_permitidos
    const [opcionesTemp, setOpcionesTemp] = useState([...opciones]);


    // Función para manejar el cambio en los permisos
    const handleCheckboxChange = (rolId, opcionId) => {
        // Actualizar el estado de las opciones al cambiar el checkbox
        const updatedOpciones = opcionesTemp.map((opcion) => {
            if (opcion.id === opcionId) {
                // Verificar si el rol ya está presente en roles_permitidos
                const isRolPresent = opcion.roles_permitidos.includes(rolId);
                // Si el rol está presente, lo eliminamos; si no, lo agregamos
                const updatedRolesPermitidos = isRolPresent
                    ? opcion.roles_permitidos.filter((id) => id !== rolId) // Eliminar rol
                    : [...opcion.roles_permitidos, rolId]; // Añadir rol
                return { ...opcion, roles_permitidos: updatedRolesPermitidos }; // Actualizamos la opción
            }
            return opcion; // Si no es la opción que se ha modificado, la dejamos igual
        });
        setOpcionesTemp(updatedOpciones);
        debouncedHandleSearch(updatedOpciones, rolId);
    };

    const actualizarPermisos  = async (updatedOpciones, rolid) => {

        try {
            const response = await actualizarRolesYDepartamentos(updatedOpciones, nombreArray, rolid);
           
        } catch (error) {
            console.error('Error al cargar las opciones:', error);
        }
    }
    const debouncedHandleSearch = useCallback(debounce(actualizarPermisos, 1000), []);


    return (
        <>
            {opciones && opciones.length >= 1 && (
                <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'} xs={12}>
                    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} xs={12}>
                        <h2>{title}</h2>
                    </Grid>
                    <Grid container direction={'column'} alignItems={'center'} justifyContent={'center'} xs={11.5} md={6.5}>

                        <TableContainer component={Paper} sx={{ minWidth: "100%" }}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>Roles</strong></TableCell>
                                        {opciones.map((opcion) => (
                                            <TableCell key={opcion.id} align="center">
                                                <h3>{opcion.nombre}</h3>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* Filas de roles */}
                                    {roles.map((rol) => (
                                        <TableRow key={rol.id}>
                                            <TableCell><strong>{rol.nombre}</strong></TableCell>
                                            {opcionesTemp.map((opcion) => (
                                                <TableCell key={`${rol.id}-${opcion.id}`} align="center">
                                                    <Checkbox
                                                        checked={opcion.roles_permitidos.includes(parseInt(rol.id))} // Verificar si el rol está en roles_permitidos
                                                        onChange={() => handleCheckboxChange(parseInt(rol.id), opcion.id)} // Manejar el clic
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                </Grid>
            )}
        </>
    );
};
