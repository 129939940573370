import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import { getOpcionesAdmin } from '../../services/apis';
import { OpcionesMenuNav } from '../../utils/componentes-utiles/OpcionesMenuNav';

export const Admin = () => {
    const Navigate = useNavigate();

    const [opciones, setOpciones] = useState([]);
    const [loaded, setLoaded] = useState([]);

    const cargarOpciones = async () => {
        try {
            const response = await getOpcionesAdmin();
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al añadir el cliente:", error);
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <div>
            <SubHeader></SubHeader>

            <h1>ADMINISTRADOR</h1>

            <OpcionesMenuNav opciones={opciones}></OpcionesMenuNav>
        </div>
    )
}
