import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Groups2Icon from '@mui/icons-material/Groups2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { getopcinesMenu } from '../services/apis'
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ForestIcon from '@mui/icons-material/Forest';
import TonalityIcon from '@mui/icons-material/Tonality';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import ScienceIcon from '@mui/icons-material/Science';

//mapa de iconos
const iconMapping = {
    TodayIcon: <TodayIcon />,
    FolderSpecialIcon: <FolderSpecialIcon />,
    Groups2Icon: <Groups2Icon />,
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
    QuickreplyIcon: <QuickreplyIcon />,
    ConstructionIcon: <ConstructionIcon />,
    AccessAlarmIcon: <AccessAlarmIcon />,
    ForestIcon: <ForestIcon />,
    TonalityIcon: <TonalityIcon />,
    RiceBowlIcon: <RiceBowlIcon />,
    FiberSmartRecordIcon: <FiberSmartRecordIcon />,
    ScienceIcon: <ScienceIcon />
    

};

export const MenuLateral = () => {
    // Global
    const navigate = useNavigate();

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);
    // Estados útiles
    const [opciones, setOpciones] = useState([]);

    // Cargar opciones menú principal
    const cargarOpciones = async () => {
        try {
            const response = await getopcinesMenu();
            // console.log("aa", response.data)
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al cargar opciones del menu:", error);
            localStorage.clear();
             navigate("/login");
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <div className='menu-lateral fade-in-2'>
            {!loaded && (<CircularProgress />)}
            {loaded && (
                <Grid xs={12} container  justifyContent="center" padding={"0.8em"}>
                    {/* <Fichar></Fichar> */}
                    {opciones.map((opcion) => (<>
                        <Grid item xs={12} key={opcion.id}>
                            {opcion.tipo == 1 && (
                                iconMapping[opcion.icon]
                            )}
                            {
                                opcion.tipo == 0 && (
                                    <Grid container direction={'column'} xs={12} onClick={() => navigate(opcion.url)} style={{ cursor: 'pointer' }}>
                                        <Grid container direction={'row'} justifyContent={'start'} alignItems={'center'} xs={12}>
                                            <IconButton color='primary'>
                                                {iconMapping[opcion.icon]}
                                            </IconButton>
                                            <Typography variant="p" component="div">
                                                {opcion.nombre.toUpperCase()}
                                            </Typography>
                                        </Grid>

                                        <Divider />

                                    </Grid>

                                )}
                        </Grid>
                    </>
                    ))}
                </Grid>
            )}
        </div>
    );
};
