import React, { useEffect, useState } from 'react'
import { SubHeader } from '../../../utils/componentes-utiles/SubHeader'
import { getUsuarios } from '../../../services/apis';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Grid } from '@mui/material';
import { UsuarioCard } from './UsuarioCard';
import { FormCrearUsuario } from './FormCrearUsuario';
import { OpcionesMenuFunc } from '../../../utils/componentes-utiles/OpcionesMenuFunc';
import { EditarUsuario } from './EditarUsuario';


export const Usuarios = () => {
	const navigate = useNavigate();

	//estados
	const [usuarios, setUsuarios] = useState([]);
	const [departamentos, setDepartamentos] = useState([]);
	const [roles, setRoles] = useState([]);
	const [clientes, setClientes] = useState([]);
	const [opciones, setOpciones] = useState([]);
	const [usuariosClientes, setUsuariosClientes] = useState([]);
	const [opcionesUsuario, setOpcionesUsuario] = useState([]);

	const [loaded, setLoaded] = useState(false);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		cargarUsuarios();
	}, [])

	const cargarUsuarios = async () => {
		try {
			const response = await getUsuarios();
			if (response.success == true) {
				setUsuarios(response.data.usuarios);
				setRoles(response.data.roles);
				setDepartamentos(response.data.departamentos);
				setClientes(response.data.clientes);
				setUsuariosClientes(response.data.usuarios_clientes);
				setOpciones(response.data.opciones);
				setOpcionesUsuario(response.data.opciones_usuario);
				setLoaded(true);
			} else {

				navigate('/');
			}

		} catch (error) {
			console.error("Error al cargar usuarios:", error);
			navigate('/');
		}
	}
	const handleEvent = (opcionId) => {

		switch (opcionId) {
			case 1:
				setOpen(true);

				// Lógica para la opción 1
				break;

			default:
				console.log("Lógica para una opción desconocida ");

				// Lógica para una opción desconocida
				break;
		}
	}

	return (
		<div className='menu-back fade-in2'>
			<SubHeader />
			<h1>USUARIOS</h1>
			{!loaded && (<CircularProgress />)}
			{loaded && (
				<>
					<Grid container direction={'column'} xs={12} >
						<OpcionesMenuFunc opciones={opciones} handleEvent={handleEvent}></OpcionesMenuFunc>
					</Grid>

					<Grid container direction="row" padding="1em" justifyContent={'space-around'} alignItems={'start'}>

						{/* Contenedor de Empleados */}
						<Grid container xs={12} sm={5} md={5} lg={5}>
							<Grid container direction="column" xs={12} justifyContent="center">
								<h3>Empleados ({usuarios.length > 0 ? usuarios.length : ''})</h3>
							</Grid>
							<Grid container xs={12} rowSpacing={3} columnSpacing={1} direction={'row'} justifyContent={"start"}>

								{usuarios.length > 0 ? (
									usuarios.map((usuario, index) => (
										<Grid item xs={12} sm={12} md={8} lg={6} xl={4} key={usuario.id}>
											<UsuarioCard usuario={usuario} opciones={opcionesUsuario} cargarUsuarios={cargarUsuarios} roles={roles} clientes={clientes} departamentos={departamentos} />
										</Grid>
									))
								) : (
									<p>No hay usuarios disponibles</p>
								)}
							</Grid>
						</Grid>

						{/* Contenedor de Clientes */}
						{usuariosClientes.length > 0 && (
							<Grid container xs={12} sm={5} md={5} lg={5}>
								<Grid container direction="row" xs={12} justifyContent="center">
									<h3>Clientes</h3>
								</Grid>
								<Grid container xs={12} rowSpacing={3} columnSpacing={1} direction={'row'} justifyContent={"start"}>

									{usuariosClientes.length > 0 ? (
										usuariosClientes.map((usuario,) => (
											<Grid item xs={12} sm={12} md={8} lg={6} xl={4} key={usuario.id}>
												<UsuarioCard usuario={usuario} opciones={opcionesUsuario} cargarUsuarios={cargarUsuarios} roles={roles} clientes={clientes} departamentos={departamentos} />
											</Grid>
										))
									) : (
										<p>No hay usuarios de clientes disponibles</p>
									)}
								</Grid>
							</Grid>
						)}
					</Grid>

					{/* Formulario de creación de usuario */}
					{open && (
						<FormCrearUsuario
							open={open}
							setOpen={setOpen}
							cargarUsuarios={cargarUsuarios}
							departamentos={departamentos}
							roles={roles}
							clientes={clientes}
						/>
					)}
				</>
			)}
		</div>
	);
};