import React, { useEffect } from 'react';

export const NotificacionFichar = () => {
	useEffect(() => {
		// Comprobar si la API de Notificaciones está disponible
		if (!('Notification' in window)) {
			console.warn('Las notificaciones no son compatibles con este navegador.');
			return;
		}

		// Solicitar permiso para notificaciones
		const solicitarPermiso = async () => {
			if (Notification.permission !== 'granted') {
				const permiso = await Notification.requestPermission();
				if (permiso === 'granted') {
					console.log('Permiso concedido para notificaciones.');
				} else {
					console.log('Permiso denegado para notificaciones.');
				}
			}
		};

		solicitarPermiso();

		// Función para verificar la hora actual y enviar una notificación
		const verificarHoraYNotificar = () => {
			const ahora = new Date();
			const hora = ahora.getHours();
			const minuto = ahora.getMinutes();

			// Comprobar si la hora es 14:00
			if (hora === 13 && minuto === 58) {
				if (Notification.permission === 'granted') {
					new Notification('¡Es hora de cerrar el fichaje!', {
						body: 'Recuerda fichar ahora.',
						icon: '/path/to/icon.png', // Cambia el icono según tus recursos
					});
				} else {
					console.log('Notificaciones no permitidas.');
				}
			}
			if (hora === 18 && minuto === 28) {
				if (Notification.permission === 'granted') {
					new Notification('¡Es hora de cerrar el fichaje!', {
						body: 'Recuerda fichar ahora.',
						icon: '/path/to/icon.png', // Cambia el icono según tus recursos
					});
				} else {
					console.log('Notificaciones no permitidas.');
				}
			}
			if (hora === 9 && minuto === 5) {
				if (Notification.permission === 'granted') {
					new Notification('¡Es hora de fichar!', {
						body: 'Recuerda fichar ahora.',
						icon: '/path/to/icon.png', // Cambia el icono según tus recursos
					});
				} else {
					console.log('Notificaciones no permitidas.');
				}
			}
			if (hora === 15 && minuto === 35) {
				if (Notification.permission === 'granted') {
					new Notification('¡Es hora de fichar!', {
						body: 'Recuerda fichar ahora.',
						icon: '/path/to/icon.png', // Cambia el icono según tus recursos
					});
				} else {
					console.log('Notificaciones no permitidas.');
				}
			}
		};

		// Configurar un intervalo para revisar la hora cada minuto
		const intervalo = setInterval(() => {
			verificarHoraYNotificar();
		}, 35000); // Revisión cada minuto

		// Limpiar el intervalo al desmontar el componente
		return () => clearInterval(intervalo);
	}, []);

	return null; // Este componente no tiene contenido visual
};
