import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Grid, IconButton, Button } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';  // Importa el ícono para abrir el menú lateral
import logo from '../media/logo.png';
import logoEmpresa from '../media/logoempresa.png';

import { CerrarSesion } from '../utils/componentes-utiles/CerrarSesion';
import { NotificacionFichar } from '../utils/componentes-utiles/NotificacionFichar';

export const Header = ({ setOpenDrawer, openDrawer }) => {
	const [tokenusu, setTokenUsu] = useState('');
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		setTokenUsu(localStorage.getItem('DesRepresentante'));
	}, [location]);

	return (
		<Grid container xs={12} justifyContent="space-between" direction="row" alignItems="center" padding="0.5em" className="header">
			{/* Ícono de menú lateral solo en pantallas pequeñas */}


			{/* Notificaciones */}
			<NotificacionFichar />

			{/* Nombre de usuario */}
			{tokenusu && (
				<Grid
					container
					xs={12} gap={1}
					justifyContent="space-between"
					direction="row"
					alignItems="center"
				>
					<Grid
						container
						direction="row"
						justifyContent={{ xs: 'start', md: 'center' }}
						alignItems="center"
						xs={4} sm={2} md={1}
					>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={() => setOpenDrawer(!openDrawer)}  // Abre el menú lateral
						>
							<MenuIcon />
						</IconButton>
						<div className="user-logged">
							{tokenusu ? <AccountCircleIcon sx={{ fontSize: 40 }} /> : ''}
							&nbsp;<div>{tokenusu}</div>
						</div>

					</Grid>

					<Grid
						container
						direction={{ xs: 'column', sm: 'row', md: 'row' }}
						justifyContent="space-evenly"
						alignItems="center"
						xs={5} sm={5} md={8}
					>
						<Grid item xs={12} sm={6} md={3} lg={1.5}>
							<img src={logo} alt="Logo" className="logo-header" onClick={() => navigate('/')} />
						</Grid>

						{/* <Grid item xs={12} sm={6} md={3} lg={1.5}>
							<img src={logoEmpresa} alt="Logo" className="logo-header" onClick={() => navigate('/')} />
						</Grid> */}

					</Grid>

					<Grid
						container
						direction="row"
						justifyContent="center"
						alignItems="center"
						xs={2} md={1}
					>
						<CerrarSesion />
					</Grid>

				</Grid>

			)}
		</Grid>
	);
};
