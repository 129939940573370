import React, { useEffect, useState } from 'react'
import { redirect, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { Button, Fab, Grid } from '@mui/material';

export const SubHeader = () => {
    const Navigate = useNavigate();


    const goBack = () => {
        window.history.back();
    }

    return (
        <Grid container xs={12} justifyContent={'space-between'}>
            <Fab className='returnButton' variant="contained" size='small' color="primary" onClick={() => goBack()}> <ArrowBackIcon
                // sx={{ padding: 0.3, fontSize: 30}} 
                fontSize="medium" /> </Fab>
            <Fab className='returnButton' variant="contained" size='small' color="primary" onClick={() => Navigate("/")}> <HomeIcon
                // sx={{ padding: 0.3, fontSize: 30}} 
                fontSize="medium" /></Fab>
        </Grid>
    )
}
