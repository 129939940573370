import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';

import { Login } from '../pages/Login';
import { MainMenu } from '../pages/MainMenu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RecursosHumanos } from '../global/rrhh/RecursosHumanos';
import { Calendario } from '../global/calendario/Calendario';
import { Admin } from '../global/administrador/Admin';

import { Usuarios } from '../global/administrador/usuarios/Usuarios';
import { Notificaciones } from '../global/administrador/notificaciones/Notificaciones';
import { Header } from '../global/Header';

import { RolesYDepartamentos } from '../global/administrador/permisos/RolesYDepartamentos';

import { Footer } from '../global/Footer';
import { LateralMenu } from '../global/LateralMenu';
import { Box, Button, Collapse, Grid } from '@mui/material';
import { Socios } from '../components/socios/Socios';
import { Fincas } from '../components/fincas/Fincas';
import { Parcelas } from '../components/parcelas/Parcelas';
import { Variedades } from '../components/plantaciones/variedades-fincas/Variedades';
import { VariedadesPorFinca } from '../components/plantaciones/variedades-fincas/VariedadesPorFinca';


// colores de los componentes del MUI
const theme = createTheme({
	palette: {
		primary: {
			main: '#83b600',
		},
		secondary: {
			main: '#f1f8e9',
		},
	},

});

const Router = () => {
	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [openDrawer, setOpenDrawer] = useState(
		localStorage.getItem('openDrawer') === 'true'
	);

	useEffect(() => {
		localStorage.setItem('openDrawer', openDrawer);
	}, [openDrawer]);
	return (
		<ThemeProvider theme={theme}>
			<div className='back-main fade-in'>
				<Grid container xs={12} direction={'column'}>
					<Grid container xs={12} direction={'row'}>
						<Header setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
					</Grid>
					<Grid container xs={12} direction={'row'}>
						<Grid container xs={12} lg={2} >
							{openDrawer && (
								<LateralMenu setOpenDrawer={setOpenDrawer} openDrawer={openDrawer}></LateralMenu>
							)}
						</Grid>
						<Grid container xs={12} lg={openDrawer ? 10 : 12} >
							<Grid container xs={12} direction={'row'}  >
								<Grid container xs={12} direction={'column'}  >
									<Routes>
										<Route element={<PrivateRoute />}>
											<Route path="/" element={<MainMenu />}></Route>
											{/* //visualcoop urls */}
											<Route path="/Socios" element={<Socios />}></Route>
											{/* <Route path="/plantaciones-finca/:id" element={<PlantacionPorFinca />}></Route> */}
											<Route path="/variedades-finca/:id" element={<VariedadesPorFinca />}></Route>
											{/* <Route path="/plantaciones" element={<Plantaciones />}></Route> */}
											<Route path="/Fincas" element={<Fincas />}></Route>
											<Route path="/Parcelas" element={<Parcelas />}></Route>
											<Route path="/Variedades" element={<Variedades />}></Route>
											{/* ///////////// */}
										
											{/* //recursos humanos */}
											<Route path="/rrhh" element={<RecursosHumanos />}></Route>
											{/* //calendario */}
											<Route path="/calendario" element={<Calendario />}></Route>
											{/* //admin */}
											<Route path="/admin" element={<Admin />}></Route>
											{/* //usuarios */}
											<Route path="/usuarios" element={<Usuarios />}></Route>
											{/* //departamentos */}
											<Route path="/rolesydepartamentos" element={<RolesYDepartamentos />}></Route>
											{/* //notificaciones */}
											<Route path="/notificaciones" element={<Notificaciones />}></Route>
											{/* //fichajes */}
											
										</Route>
										{/* //global */}
										<Route path="/login?" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
										<Route path="*" element={<Navigate to="/" replace />} />
									</Routes>
								</Grid>

							</Grid>
						</Grid>
						<Footer></Footer>
					</Grid>
				</Grid>

			</div>
		</ThemeProvider >
	)
}

export default Router