import Swal from "sweetalert2";
 //advertencias
 export const advertenciaEliminar = async (titulo, texto) => {
    const result = await Swal.fire({
      title: titulo,
      text: texto,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--principal)",
      cancelButtonColor: "var(--secundario)",
      confirmButtonText: "¡Sí, eliminar!",
      cancelButtonText: "Cancelar",
    });
  
    return result.isConfirmed; // Devuelve `true` si el usuario confirmó
  };