import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { SubHeader } from '../../utils/componentes-utiles/SubHeader';
import { getOpcionesRrhh } from '../../services/apis';
import { CircularProgress } from '@mui/material';
import { OpcionesMenuNav } from '../../utils/componentes-utiles/OpcionesMenuNav';

export const RecursosHumanos = () => {
	;

	const [opciones, setOpciones] = useState([]);
	const [loaded, setLoaded] = useState(false);

	// Cargar opciones menú principal
	const cargarOpciones = async () => {
		try {
			const response = await getOpcionesRrhh();
			setOpciones(response.data)
			setLoaded(true);
			console.log(response.data)
		} catch (error) {
			console.error("Error al añadir el cliente:", error);

		}
	};

	useEffect(() => {
		cargarOpciones();
	}, []);
	return (
		<div>
			<SubHeader></SubHeader>
			<h1>RECURSOS HUMANOS</h1>
			{!loaded && (<CircularProgress />)}
			{loaded && (
				<OpcionesMenuNav opciones={opciones} />
			)}
		</div>
	)
}
