import React from 'react'
import JC from '../media/JC.png'
import { Navigate, useNavigate } from 'react-router-dom'

export const Footer = () => {
    const navigate = useNavigate();


    return (
        <div className='footer'>
            <img src={JC} alt="" style={{ maxWidth: '200px' }} onClick={() => window.open("https://www.jcinformatics.com/", "_blank")} />
            <small>&nbsp;Alpha 2.4</small>
        </div>
    )
}
