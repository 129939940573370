import React from "react";
import { useDroppable } from "@dnd-kit/core";
import { Paper, Typography } from "@mui/material";

export default function DroppableArea({ id, activeTask }) {
  const { setNodeRef, isOver } = useDroppable({ id });

  return (
    <Paper
      ref={setNodeRef}
      sx={{
        minHeight: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isOver ? "#b3e5fc" : "#e0e0e0",
        width: "100%",
        padding: "8px",
        transition: "background-color 0.3s",
        border: isOver ? "2px dashed #1976d2" : "2px dashed transparent",
      }}
    >
      {isOver && activeTask ? (
        <Typography variant="body2" fontWeight="bold" color="primary">
          {activeTask}
        </Typography>
      ) : (
        <Typography variant="body2" color="textSecondary">
          Arrastra aquí
        </Typography>
      )}
    </Paper>
  );
}
