import { Button, Checkbox, containerClasses, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useRef, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import { useParams } from 'react-router-dom';
import { postTarea, postUsuario } from '../../../services/apis';
import { AlertError } from '../../../utils/componentes-utiles/AlertError';
import SquareIcon from '@mui/icons-material/Square';
import AutocompleteList from '../../../utils/componentes-utiles/AutocompleteList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoopIcon from '@mui/icons-material/Loop';
import { ContasenaOculta } from '../../../utils/componentes-utiles/ContasenaOculta';
import { generarColorAleatorio } from '../../../utils/utils';
import { CrearCliente } from './CrearCliente';

export const FormCrearUsuario = ({ open, setOpen, cargarUsuarios, roles, departamentos, clientes }) => {
    const handleClose = () => {
        setOpen(false);
    };
    const param = useParams();
    const [errorAlert, setErrorAlert] = useState(false);
    const [errorPassAlert, setErrorPassAlert] = useState(false);
    const [erroRespuesta, setErroRespues] = useState(false);
    const [rol, setRol] = useState(0);
    const [departamento, setDepartamento] = useState(0);
    const [camara, setCamara] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
    const [contrasena, setContrasena] = useState("");
    const [contrasenaCon, setContrasenaCon] = useState("");
    const [esCliente, setEsCliente] = useState(false);
    const [recibeSa, setRecibeSa] = useState(false);

    const [usuario, setUsuario] = useState({
        'nombre': null,
        'mail': null,
        'id_rol': null,
        'id_departamento': null,
        'color': generarColorAleatorio(),
        'contrasena': null,
        'sa': 0,
        'id_cliente': null
    })

    const handleChange = (event) => {
        const tareaNueva = { ...usuario, [event.target.name]: event.target.value };
        setUsuario(tareaNueva);
        setErrorAlert(false);
    }
    const isNullInObject = (obj) => {
        const camposExcepcion = ['sa', 'foto', 'id_cliente'];
        return Object.keys(obj).every(key => {
            if (camposExcepcion.includes(key)) {
                return true;
            }
            return obj[key] !== null && obj[key] !== '' && obj[key] != 0;
        });
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    //camara
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagenSeleccionada(reader.result);
            setCamara(true);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const fileInputRef = useRef(null);

    const handleLoopIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleCheckboxChange = (event) => {
        if (event.target.name === 'esCliente') {
            setEsCliente(event.target.checked);
            if (event.target.checked) {
                setRecibeSa(false); // Desactivar el otro checkbox
                setUsuario({ ...usuario, sa: 0 });
            }
        } else if (event.target.name === 'recibeSa') {
            setRecibeSa(event.target.checked);
            if (event.target.checked) {
                setEsCliente(false); // Desactivar el otro checkbox
                setUsuario({ ...usuario, id_cliente: null });
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let usuarioEnvio = { ...usuario };
        if ((contrasena === contrasenaCon) && (contrasena != "" || contrasenaCon != "")) {
            usuarioEnvio.contrasena = contrasena;
            usuarioEnvio.color = color;
            usuario.sa = recibeSa;
            const tareaComprobada = isNullInObject(usuarioEnvio);
            if (!tareaComprobada) {
                console.error("Hay campos vacíos o no válidos en el formulario");
                setErrorAlert(true);
                return; // Detener la ejecución si hay campos no válidos
            }
        } else {
            console.error("Revisa que las contraseñas coincidan o no esten vacias");
            setErrorPassAlert(true);
            return; // Detener la ejecución si hay campos no válidos
        }

        crearUsuario(usuarioEnvio);
    };
    const [color, setColor] = useState(`${usuario.color}`);
    const colorInputRef = useRef(null);
    const crearUsuario = async (usuarioEnvio) => {
        const nuevoUsuario = { ...usuarioEnvio };
        try {
            const response = await postUsuario(nuevoUsuario);
            if (response.success === true) {
                cargarUsuarios();
                setUsuario({
                    'nombre': null,
                    'mail': null,
                    'id_rol': null,
                    'id_departamento': null,
                    'color': null,
                    'contrasena': null,
                    'sa': 0,
                });
                setDepartamento({ label: "Aginar a un Trabajador", value: "0" });
                handleClose();
            } else if (response.success === false) {
                setErroRespues(response.error);

            }
        } catch (error) {
            console.error("Error Crear el Usuario", error);
            setErroRespues(true);

        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            aria-describedby="form-dialog-description"
            fullWidth
            justifyContent="center"
        >
            <Grid container xs={12} spacing={2} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                <DialogTitle id="form-dialog-title">
                    Nuevo/a Usuario/a
                </DialogTitle>
                <div className='flex-right'>
                    <CancelIcon onClick={handleClose} style={{ cursor: 'pointer' }} />
                </div>
            </Grid>
            <DialogContent >
                <form onSubmit={handleSubmit}>
                    <Grid container xs={12} spacing={1} direction="row" gap={1} alignItems={'center'} justifyContent={"center"}>
                        <Grid container xs={12} direction={'row'} alignItems={'start'} justifyContent={"center"}>
                            <Grid container xs={5} direction={'column'} justifyContent={"center"}>

                                <Grid container justifyContent={'center'}>
                                    Foto del Usuario
                                </Grid>
                                {!camara && !imagenSeleccionada && (
                                    <>
                                        <Grid container justifyContent={'center'}>
                                            <AccountCircleIcon sx={{ fontSize: 70 }} onClick={handleLoopIconClick} />
                                        </Grid>
                                        <input
                                            ref={fileInputRef}
                                            type='file'
                                            style={{ display: 'none' }}
                                            accept='image/*'
                                            onChange={handleFileInputChange}
                                        />
                                    </>
                                )}
                                {(imagenSeleccionada && !camara) && (
                                    <Grid container xs={12} spacing={2} alignItems={"center"} justifyContent={"center"} padding={"1em"}>
                                        <img src={imagenSeleccionada} onClick={() => setCamara(true)} alt="Imagen seleccionada" className='miniatura-perfil-form' />
                                    </Grid>
                                )}

                                {(imagenSeleccionada && camara) && (
                                    <Grid container spacing={2} direction="column" alignItems={"center"} justifyContent={"center"} padding={"1em"}>
                                        <img src={imagenSeleccionada} alt="Imagen seleccionada" className='miniatura-perfil-form' onClick={handleLoopIconClick} />
                                        <Grid container xs={10} sm={6} md={6} xl={6} spacing={2} direction="row" alignItems={"center"} padding={"1em"} justifyContent={"space-between"}>

                                            <CheckIcon className='flex-center' onClick={() => setCamara(false)} />
                                            <LoopIcon className='flex-center' onClick={handleLoopIconClick} />

                                            <input
                                                ref={fileInputRef}
                                                type='file'
                                                style={{ display: 'none' }}
                                                accept='image/*'
                                                onChange={handleFileInputChange}
                                            />

                                            <DeleteForeverIcon className='flex-center' onClick={() => { setImagenSeleccionada(null); setCamara(false); }}></DeleteForeverIcon>
                                        </Grid>

                                    </Grid>
                                )}

                            </Grid>

                            <Grid container xs={5} direction={'column'} alignContent={'center'} justifyContent={"center"}>
                                <Grid container justifyContent={'center'}>
                                    Color del Usuario
                                </Grid>
                                <Grid container justifyContent={'center'}>

                                    <input
                                        type="color"
                                        ref={colorInputRef}
                                        value={color}
                                        onChange={handleColorChange}
                                        id="primary_color"
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={5} justifyContent={"center"}>
                            <TextField
                                label="Nombre"
                                variant="outlined"
                                multiline
                                fullWidth
                                name="nombre"
                                value={usuario.nombre}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={5} justifyContent={"center"}>
                            <TextField
                                label="Mail"
                                variant="outlined"
                                multiline
                                fullWidth
                                type='mail'
                                name="mail"
                                value={usuario.mail}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={5} justifyContent={"center"}>
                            <ContasenaOculta contrasena={contrasena} setContrasena={setContrasena}></ContasenaOculta>
                        </Grid>
                        <Grid item xs={5} justifyContent={"center"}>
                            <ContasenaOculta contrasena={contrasenaCon} setContrasena={setContrasenaCon}></ContasenaOculta>
                        </Grid>
                        <Grid container xs={5} justifyContent={"center"}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
                                <Select
                                    color='primary'
                                    value={usuario.id_departamento || ''}
                                    name={"id_departamento"}
                                    onChange={handleChange}
                                    defaultValue="Departamento"
                                    label="Departamento"
                                    fullWidth
                                >
                                    {departamentos.map((departamento) => (
                                        <MenuItem
                                            key={departamento.id}
                                            value={departamento.id}
                                            onChange={handleChange}
                                        >
                                            {departamento.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container xs={5} justifyContent={"center"}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                    color='primary'
                                    value={usuario.id_rol || ''}
                                    name={"id_rol"}
                                    onChange={handleChange}
                                    defaultValue="Rol"
                                    label="Rol"
                                    fullWidth
                                >
                                    {roles.map((rol) => (
                                        <MenuItem
                                            key={rol.id}
                                            value={rol.id}
                                            onChange={handleChange}
                                        >
                                            {rol.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid container xs={12} direction={'row'} justifyContent={"center"}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={esCliente}
                                        onChange={handleCheckboxChange}
                                        name="esCliente"
                                    />
                                }
                                label="Es Cliente"
                            />
                            { }
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={recibeSa}
                                        onChange={handleCheckboxChange}
                                        name="recibeSa"
                                    />
                                }
                                label="Recibe SA"
                            />
                        </Grid>
                        {esCliente && (
                            <Grid container xs={12} justifyContent={"center"}>

                                <CrearCliente cargarUsuarios={cargarUsuarios} setErroRespues={setErroRespues}></CrearCliente>
                                <Grid container xs={5} justifyContent={"center"}>

                                    ¿No sale el cliente en la lista? rellena el campo
                                    de arriba y dale a Crear CLiente
                                </Grid>

                                <Grid container xs={12} justifyContent={"center"}>
                                    <Grid container xs={5} justifyContent={"center"} marginTop={"1em"}>

                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
                                            <Select
                                                color='primary'
                                                value={usuario.id_cliente || ''}
                                                name={"id_cliente"}
                                                onChange={handleChange}
                                                defaultValue="Cliente"
                                                label="Cliente"
                                                fullWidth
                                            >
                                                {clientes.map((cliente) => (
                                                    <MenuItem
                                                        key={cliente.id}
                                                        value={cliente.id}
                                                        onChange={cliente}
                                                    >
                                                        {cliente.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                </Grid>
                            </Grid>

                        )}
                    </Grid>
                </form>
            </DialogContent >

            <DialogActions style={{
                // backgroundColor: "#" + backgroundCard,
                // color: textColor
            }}>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Crear Usuario
                </Button>
            </DialogActions>
            {
                errorAlert && (
                    <AlertError open={errorAlert} setOpen={setErrorAlert} titulo={"Error"} mensaje={"Falta rellenar algún campo obligatorio"} />
                )

            }
            {
                errorPassAlert && (
                    <AlertError open={errorPassAlert} setOpen={setErrorPassAlert} titulo={"Error"} mensaje={"Las contraseñas no coinciden"} />
                )

            }
            {
                erroRespuesta && (
                    <AlertError open={erroRespuesta} setOpen={setErroRespues} titulo={"Error"} mensaje={erroRespuesta} />
                )
            }
        </Dialog >
    );
};
