import { Button, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { postCliente, postUsuario } from '../../../services/apis';

export const CrearCliente = ({cargarUsuarios, setErroRespues}) => {

    const [cliente, setCliente] = useState({ nombre: null });

    const handleChange = (event) => {
        const tareaNueva = { ...cliente, [event.target.name]: event.target.value };
        setCliente(tareaNueva);
    }

    const handleSubmit = () => {
     

        crearUsuario(cliente);
    };

        const crearUsuario = async (usuarioEnvio) => {
            const nuevoUsuario = { ...usuarioEnvio };
            try {
                const response = await postCliente(nuevoUsuario);
                if (response.success === true) {
                    cargarUsuarios();
                    setCliente({
                        'nombre': null                    
                        
                    });
                 
                } else if (response.success === false) {
                    setErroRespues(response.error);
    
                }
            } catch (error) {
                console.error("Error Crear el Usuario", error);
                setErroRespues(true);
    
            }
        };
    return (
        <Grid container xs={12} direction={'column'} alignContent={'center'} justifyContent={"center"} padding={"1em"}>
            <Grid item xs={5} justifyContent={"center"} marginBottom={"0.5em"}>
                <TextField
                    label="Nuevo Cliente"
                    variant="outlined"
                    multiline
                    fullWidth
                    name="nombre"
                    value={cliente.nombre}
                    onChange={handleChange}
                />
            </Grid>
            <Button onClick={handleSubmit} color="primary" variant="contained">
                Crear Cliente
            </Button>
        </Grid>
    )
}
