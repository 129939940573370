import React, { useState } from "react";
import { DndContext, closestCenter, DragOverlay } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import { addDays, format, startOfWeek } from "date-fns";
import { Grid, Paper, Typography } from "@mui/material";
import DroppableArea from "./DroppableArea";

export const Calendario = () => {
  // Obtener la semana actual
  const startDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  const days = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

  // Estado de las tareas con ejemplos iniciales
  const [tasks, setTasks] = useState({
    [format(days[0], "yyyy-MM-dd")]: ["Revisar correos", "Llamar a clientes"],
    [format(days[1], "yyyy-MM-dd")]: ["Reunión con equipo"],
    [format(days[2], "yyyy-MM-dd")]: ["Escribir informe"],
    [format(days[3], "yyyy-MM-dd")]: [],
    [format(days[4], "yyyy-MM-dd")]: ["Enviar propuesta"],
    [format(days[5], "yyyy-MM-dd")]: [],
    [format(days[6], "yyyy-MM-dd")]: ["Planificación semanal"],
  });

  // Estado para la tarea que se está arrastrando
  const [activeTask, setActiveTask] = useState(null);

  // Manejar inicio del drag
  const onDragStart = (event) => {
    setActiveTask(event.active.id);
  };

  // Manejar finalización del drag
  const onDragEnd = (event) => {
    const { active, over } = event;
    setActiveTask(null);
    if (!over || active.id === over.id) return;

    const activeDate = Object.keys(tasks).find((date) =>
      tasks[date].includes(active.id)
    );
    const overDate = over.id;

    if (activeDate === overDate) {
      setTasks((prev) => ({
        ...prev,
        [activeDate]: arrayMove(
          prev[activeDate],
          prev[activeDate].indexOf(active.id),
          prev[activeDate].indexOf(over.id)
        ),
      }));
    } else {
      setTasks((prev) => ({
        ...prev,
        [activeDate]: prev[activeDate].filter((task) => task !== active.id),
        [overDate]: [...prev[overDate], active.id],
      }));
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Grid container spacing={2} padding={2}>
        {days.map((day) => {
          const dateKey = format(day, "yyyy-MM-dd");
          return (
            <Grid item xs={12} sm={6} md={4} lg={1.7} key={dateKey}>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: "#f5f5f5",
                  minHeight: 150,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "background-color 0.3s",
                }}
              >
                <Typography variant="h6" fontWeight="bold" gutterBottom>
                  {format(day, "EEEE")}
                </Typography>
                <SortableContext
                  items={tasks[dateKey]}
                  strategy={verticalListSortingStrategy}
                >
                  {tasks[dateKey].length > 0 ? (
                    tasks[dateKey].map((task) => (
                      <SortableItem key={task} id={task} />
                    ))
                  ) : (
                    <DroppableArea id={dateKey} activeTask={activeTask} />
                  )}
                </SortableContext>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      {/* 🟢 Drag Overlay para mantener visible la tarea mientras se arrastra */}
      <DragOverlay>
        {activeTask ? <SortableItem id={activeTask} /> : null}
      </DragOverlay>
    </DndContext>
  );
};
