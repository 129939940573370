import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import SquareIcon from '@mui/icons-material/Square';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { OpcionesMenuFunc } from '../../../utils/componentes-utiles/OpcionesMenuFunc';
import { EditarUsuario } from './EditarUsuario';
import { delUsuario } from '../../../services/apis';
import { advertenciaEliminar } from '../../../utils/componentes-utiles/advertencias';


export const UsuarioCard = ({ usuario, opciones, cargarUsuarios, departamentos, roles, clientes }) => {

    const [openEdit, setOpenEdit] = useState(false);
    const handleEvent = (opcionId) => {
        switch (opcionId) {
            case 1:
                setOpenEdit(true);
                // Lógica para la opción 1
                break;
            case 2:
                eliminarUsuario(usuario.id);
                // Lógica para la opción 2
                break;

            default:
                console.log("Lógica para una opción desconocida");
                // Lógica para una opción desconocida
                break;
        }
    }
    const eliminarUsuario = async (id) => {
        const titulo = "Eliminar " + usuario.nombre;
        const texto = "Vas a eliminar permanentemente este usuario";
      
        // Asegúrate de esperar el resultado de advertenciaEliminar
        const confirmado = await advertenciaEliminar(titulo, texto);
        if (confirmado) {
          try {
            const response = await delUsuario(id);
            if (response.success === true) {
              cargarUsuarios();
            }
          } catch (error) {
            console.error("Error al eliminar la tarea:", error);
          }
        }
      
      };
    return (
        <div className="flex-center">
            <Grid
                container
                xs={12}
                gap={1}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="fichaje-card fade-in2"
            >
                <Card
                    sx={{ width: '100%', padding: 2, borderRadius: 3, boxShadow: 3, background: "var(--fondoTarjeta)" }}
                >
                    <CardContent>
                        <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <AccountCircleIcon sx={{ fontSize: 40 }} />
                            <p>{usuario.nombre} </p>
                            <SquareIcon
                                style={{
                                    cursor: 'pointer',
                                    color: usuario.color,
                                    backgroundColor: usuario.color,
                                    borderRadius: "4px",
                                    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
                                }}
                            />

                        </Grid>
                        {/* Icono de color */}
                        <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
                            <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
                                <p><b>Rol:</b> {usuario.nombre_rol}</p>
                            </Grid>
                            <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
                                <p><b>Departamento: </b>{usuario.nombre_departamento}</p>
                            </Grid>
                            {usuario.nombre_cliente && (
                                <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"space-evenly"}>
                                    <b>Cliente: </b>{usuario.nombre_cliente}
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                    {opciones && (
                        <OpcionesMenuFunc opciones={opciones} handleEvent={handleEvent}></OpcionesMenuFunc>
                    )}
                </Card>
            </Grid>
            {openEdit && (
                <EditarUsuario
                    open={openEdit}
                    setOpen={setOpenEdit}
                    cargarUsuarios={cargarUsuarios}
                    departamentos={departamentos}
                    roles={roles}
                    clientes={clientes}
                    usuarioData={usuario}
                />
            )}
        </div >
    );
};
