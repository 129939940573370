import { Drawer, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuLateral } from '../pages/MainMenuLateral';

export const LateralMenu = ({ openDrawer, setOpenDrawer, open  }) => {
    const [tokenusu, setTokenUsu] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const token = localStorage.getItem('DesRepresentante');
        setTokenUsu(token);
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('DesRepresentante');
        setTokenUsu(token);
    }, [location]);

    const toggleDrawer = (open) => {
        setOpenDrawer(open);  // Controla la apertura/cierre del Drawer
    };
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg')); // Detecta pantallas pequeñas

    return (
        <>
            {tokenusu && (
                <>
                    {isMobile && (
                        <Drawer
                            anchor="left"
                            open={openDrawer}
                            onClose={() => toggleDrawer(false)} // Cierra el menú lateral al hacer clic fuera
                            sx={{
                                display: { xs: 'block', lg: 'none' }, // Solo mostrar en pantallas pequeñas
                            }}
                            PaperProps={{
                                sx: {
                                    width: '300px', // Cambia este valor según el ancho deseado
                                },
                            }}
                        >
                            <MenuLateral />
                        </Drawer>
                    )}
                    
                    <Grid
                        xs={12}
                        container
                        sx={{
                            display: { xs: 'none', lg: 'block' },  // Solo mostrar en pantallas grandes
                        }}
                        className='fondo-lateralmenu'
                    >
                        <MenuLateral />
                    </Grid>
                </>
            )}
        </>
    );
};
