import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TodayIcon from '@mui/icons-material/Today';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import Groups2Icon from '@mui/icons-material/Groups2';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { getopcinesMenu } from '../services/apis'
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ParkIcon from '@mui/icons-material/Park';
import ForestIcon from '@mui/icons-material/Forest';
import TonalityIcon from '@mui/icons-material/Tonality';
import RiceBowlIcon from '@mui/icons-material/RiceBowl';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import ScienceIcon from '@mui/icons-material/Science';

//mapa de iconos
let tamIco = 50;
const iconMapping = {
    TodayIcon: <TodayIcon sx={{ fontSize: tamIco }}/>,
    FolderSpecialIcon: <FolderSpecialIcon sx={{ fontSize: tamIco }} />,
    Groups2Icon: <Groups2Icon sx={{ fontSize: tamIco }}/>,
    AdminPanelSettingsIcon: <AdminPanelSettingsIcon  sx={{ fontSize: tamIco }}/>,
    QuickreplyIcon: <QuickreplyIcon sx={{ fontSize: tamIco }}/>,
    ConstructionIcon: <ConstructionIcon sx={{ fontSize: tamIco }}/>,
    ForestIcon: <ForestIcon sx={{ fontSize: tamIco }}/>,
    ParkIcon: <ParkIcon sx={{ fontSize: tamIco }}/>,
    TonalityIcon: <TonalityIcon sx={{ fontSize: tamIco }}/>,
    RiceBowlIcon: <RiceBowlIcon sx={{ fontSize: tamIco }}/>,
    FiberSmartRecordIcon: <FiberSmartRecordIcon sx={{ fontSize: tamIco }} />,
    ScienceIcon: <ScienceIcon sx={{ fontSize: tamIco }} />,


    AccessAlarmIcon: <AccessAlarmIcon sx={{ fontSize: tamIco }}/>

};

export const MainMenu = () => {
    // Global
    const navigate = useNavigate();

    // Estados auxiliares
    const [loaded, setLoaded] = useState(false);

    // Estados útiles
    const [opciones, setOpciones] = useState([]);

    // Cargar opciones menú principal
    const cargarOpciones = async () => {
        try {
            const response = await getopcinesMenu();
            setOpciones(response.data)
            setLoaded(true);
        } catch (error) {
            console.error("Error al cargar opciones del menu:", error);
            // localStorage.clear();
            // navigate("/login");
        }
    };

    useEffect(() => {
        cargarOpciones();
    }, []);

    return (
        <div className='menu-principal fade-in-2'>
            {!loaded && (<CircularProgress />)}
            {loaded && (
                <Grid xs={12} container gap={3} justifyContent="center">
                    {opciones
                        .filter((opcion) => opcion.tipo === 0)
                        .map((opcion) => (
                            <Grid container xs={12} sm={6} md={4} lg={3} key={opcion.id}>
                                <Card
                                    onClick={() => navigate(opcion.url)}
                                    className="menu-card"
                                    style={{
                                        cursor: 'pointer',
                                        transition: '0.3s',
                                        borderRadius: 15,
                                        boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.4)',
                                        background: "var(--fondoTarjeta)"
                                    }}
                                >
                                    <CardContent style={{ textAlign: 'center', padding: '30px' }}>
                                        <Grid container xs={12} alignItems={'center'} justifyContent={'space-between'}>
                                            <IconButton size={'small'}  color="primary">
                                                {iconMapping[opcion.icon]}
                                            </IconButton>
                                            <Typography variant="h6" component="div">
                                                {opcion.nombre.toUpperCase()}
                                            </Typography>
                                        </Grid>

                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            )}
        </div>
    );
};
